import { Timestamp } from "firebase/firestore";

export function firestoreDateToDate(date: any) {
  if (!date) {
    return "";
  }
  try {
    const myDate = date as unknown as {
      _seconds: number;
      _nanoseconds: number;
    };

    const timestamp = new Timestamp(myDate._seconds, myDate._nanoseconds);
    return timestamp.toDate();
  } catch (e) {
    return date;
  }
}

export function dateToInputDate(date: Date) {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    return date;
  }
  return date.toISOString().split("T")[0];
}

export function dateExpired(date: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  return date < today;
}
