import { useDispatch, useSelector } from "react-redux";
import IClientBilling from "../../../interfaces/clients/IClientBilling";
import { AppDispatch } from "../../../store/actionTypes";
import { useEffect, useState } from "react";
import { fetchClientBillings } from "../../../store/clientReducer";
import { UseAppContext } from "../../../context/appContext";
import ClientBillingItemList from "./ClientBillingItemList";
import ClientBillingModal from "./ClientBillingModal";
import { Button } from "react-bootstrap";

interface Props {
  client: string;
}

export default function ClientBillingList({ client }: Props) {
  const [show, setShow] = useState(false);
  const [currentBilling, setCurrentBilling] = useState({} as IClientBilling);
  const { uid, translate } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const billings = useSelector(
    (state: any) => state.client.clientBillings
  ) as Array<IClientBilling>;

  useEffect(() => {
    if (client) {
      dispatch(fetchClientBillings({ client, professor: uid }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  function newBilling() {
    setCurrentBilling({ client: client });
    setShow(true);
  }

  function selectBilling(newBilling: IClientBilling) {
    setCurrentBilling(newBilling);
    setShow(true);
  }

  return (
    <div>
      <div className="d-flex flex-col">
        <h6>{translate("clientBilling")}</h6>
        <Button
          size="sm"
          className="me-0 ms-auto"
          onClick={() => {
            newBilling();
          }}
        >
          {translate("newBilling")}
        </Button>
      </div>

      <div>
        {(!billings || !billings.length) && (
          <p>{translate("noBillingsInfo")}</p>
        )}

        <div className="mt-2">
          {billings.map((billing) => (
            <ClientBillingItemList
              billing={billing}
              key={billing.id}
              selectBilling={selectBilling}
            />
          ))}
        </div>
      </div>

      <ClientBillingModal
        show={show}
        currentBilling={currentBilling}
        updateBilling={selectBilling}
        hide={() => {
          setShow(false);
        }}
      />
    </div>
  );
}
