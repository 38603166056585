import { Button } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import IClientBilling from "../../../interfaces/clients/IClientBilling";
import { FaCheck, FaEdit, FaUndo } from "react-icons/fa";
import { dateExpired } from "../../../functions/dateFunctions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/actionTypes";
import { editClientBilling } from "../../../store/clientReducer";
import { toast } from "react-toastify";
import { useState } from "react";

interface Props {
  billing: IClientBilling;
  selectBilling: (billing: IClientBilling) => void;
}

export default function ClientBillingItemList({
  billing,
  selectBilling,
}: Props) {
  const { translate, isMobile } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentBilling, setCurrentBilling] = useState(billing);

  function itemTextClass() {
    if (currentBilling.payed) {
      return " text-success ";
    }
    if (!currentBilling.dueDate) return "";
    return dateExpired(currentBilling.dueDate) ? " text-danger " : "";
  }

  async function toggleBillingPayed() {
    try {
      const editBillingTask = (await dispatch(
        editClientBilling({
          id: currentBilling.id,
          client: currentBilling.client,
          payed: !currentBilling.payed,
        } as any)
      )) as any;

      if (editBillingTask.payload.id) {
        toast.success(
          editBillingTask.payload?.payed
            ? translate("markedAsPayed")
            : translate("reOpen"),
          {
            position: "top-center",
            autoClose: 2000,
          }
        );

        setCurrentBilling({
          ...currentBilling,
          payed: editBillingTask.payload?.payed,
        });
      } else {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
      }

      setLoading(false);
    } catch (e: any) {
      console.error(e);
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    }
  }

  return (
    <div
      className="border rounded p-2 mb-2 d-flex bg-white"
      onClick={() => {
        console.error(billing);
      }}
    >
      <div className={"w-100" + itemTextClass()}>
        <p className="text-start text-12 mb-0">
          {billing?.date?.toLocaleDateString()}
        </p>
        <p className="text-start mb-0">
          {translate("value")}: {translate("currencySymbol")}{" "}
          {currentBilling.value}{" "}
        </p>
        <p className="text-start mb-0">
          {translate("due")}: {currentBilling.dueDate?.toLocaleDateString()}
        </p>
        <p className="text-start mb-0">
          {currentBilling.payed ? (
            translate("payed")
          ) : dateExpired(currentBilling.dueDate || new Date()) ? (
            <strong>{translate("expired")}</strong>
          ) : (
            translate("waitingPayment")
          )}
        </p>

        {currentBilling.info && (
          <div
            className="text-start mb-0 text-12  w-100 mt-2 ps-2"
            style={{
              height: "auto",
              whiteSpace: "pre-line",
              lineHeight: "0.8",
            }}
          >
            {currentBilling.info}
          </div>
        )}
      </div>
      <div className="">
        {!currentBilling.payed && (
          <Button
            size="sm"
            className="my-auto w-100"
            onClick={() => selectBilling(billing)}
            disabled={loading}
            style={{ height: "32px" }}
          >
            <FaEdit className="my-auto" />
            {!isMobile && <span className="ms-2">{translate("edit")}</span>}
          </Button>
        )}

        <Button
          size="sm"
          variant={currentBilling.payed ? "secondary" : "success"}
          className="mt-2 d-flex"
          onClick={() => toggleBillingPayed()}
          disabled={loading}
          style={{ height: "32px" }}
        >
          {currentBilling.payed ? (
            <FaUndo className="my-auto" />
          ) : (
            <FaCheck className="my-auto" />
          )}
          {!isMobile && (
            <span className="ms-1" style={{ minWidth: "150px" }}>
              {translate("confirmPayment")}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
}
