import { UseAppContext } from "../../context/appContext";
import IClient from "../../interfaces/IClient";
import { useSelector } from "react-redux";
import CreateClientButton from "./CreateClientButton";
import ClientSimpleCard from "./ClientSimpleCard";
import { Spinner } from "react-bootstrap";

export default function ClientsList() {
  const { translate } = UseAppContext();
  const clients = useSelector(
    (state: any) => state.client.clients
  ) as Array<IClient>;

  const loading = useSelector((state: any) => state.client.loading) as Boolean;

  return (
    <div>
      <h5>{translate("clientsList")}</h5>

      <div className="my-2">
        <CreateClientButton />
      </div>

      {loading && <Spinner size="sm" className="m-auto" />}

      {clients.length ? (
        clients?.map((client) => {
          return <ClientSimpleCard client={client} key={client.id} />;
        })
      ) : (
        <div>
          <p>{translate("noClientsYet")}</p>
        </div>
      )}
    </div>
  );
}
