import { Spinner } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";

interface Props {
  className?: string;
  text?: string;
  height?: string;
}

export default function LoadingDiv({ className, text, height }: Props) {
  const { translate } = UseAppContext();

  if (!height) {
    height = "70px";
  }

  return (
    <div
      className={className || "m-auto d-flex w-100"}
      style={{ minHeight: height }}
    >
      <div className="ma-auto w-100" style={{ minHeight: height }}>
        <Spinner className="mx-auto" size="sm" />
        {text && <p className="mb-0">{translate(text)}</p>}
      </div>
    </div>
  );
}
