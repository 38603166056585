import { Button, Card, Modal, Spinner } from "react-bootstrap";
import { UseAppContext } from "../../../../context/appContext";
import { useEffect, useState } from "react";
import IExerciseInfo from "../../../../interfaces/exercises/IExerciseInfo";
import { useDispatch } from "react-redux";
import { fetchExerciseInfo } from "../../../../store/workoutReducer";
import { AppDispatch } from "../../../../store/actionTypes";

interface props {
  show: boolean;
  hide: () => void;
  exercise: string;
  name: string;
}

export default function ExerciseInfoModal({
  show,
  hide,
  exercise,
  name,
}: props) {
  const { translate } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const [currentExercise, setCurrentExercise] = useState({} as IExerciseInfo);

  useEffect(() => {
    if (show && exercise) {
      getExerciceInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  async function getExerciceInfo() {
    dispatch(
      (await fetchExerciseInfo({ exercise, name, lang: "pt" } as any)) as any
    ).then((payloadResponse: any) => {
      const payload = payloadResponse?.payload as IExerciseInfo;

      if (payload) {
        setCurrentExercise(payload);
      }
    });
  }

  function isTextArea(info: string) {
    return info?.includes("\n");
  }

  function parseTextAreaText(info: string) {
    let response = info?.replaceAll("**", "");
    response = response?.replaceAll("##", "");
    response = response?.replaceAll("\n", "<br/>");
    response = response?.replaceAll("*", "•");
    return response;
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header className="py-2 text-center">
        <h4 className="text-center my-0 mx-auto">{translate("exercise")}</h4>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Card
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(85vh - 80px)",
            border: "none",
          }}
          className="px-2"
        >
          {currentExercise.id ? (
            <div>
              {isTextArea(currentExercise.info) ? (
                <div
                  className="p-2"
                  dangerouslySetInnerHTML={{
                    __html: parseTextAreaText(currentExercise.info),
                  }}
                />
              ) : (
                <div
                  className="p-2"
                  dangerouslySetInnerHTML={{ __html: currentExercise.info }}
                />
              )}
            </div>
          ) : (
            <div className="p-2 d-flex" style={{ minHeight: "100px" }}>
              <Spinner size="sm" className="m-auto" />
            </div>
          )}
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide} variant="secondary">
          {translate("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
