import { useSelector } from "react-redux";
import INotification from "../../../interfaces/notifications/INotification";
import { Dropdown } from "react-bootstrap";
import "./NotificationsDropdown.scss";
import { FaBell } from "react-icons/fa";
import NotificationItem from "./NotificationItem";

export default function NotificationsDropDown() {
  const notifications = useSelector(
    (state: any) => state.user.notifications
  ) as INotification[];

  const haveUnreadNotifications = notifications.some(
    (notification) => notification.read === false
  );

  return (
    <Dropdown align="end">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-notifications"
        className="notification-dropdown-toggle"
      >
        <FaBell
          color={
            haveUnreadNotifications ? "var(--notification)" : "var(--white)"
          }
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {notifications.length > 0 ? (
          notifications.map((notification: INotification, index) => (
            <NotificationItem
              key={notification.id || "" + index}
              notification={notification}
            />
          ))
        ) : (
          <NotificationItem />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
