import { Form } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { useEffect, useState } from "react";
import { FormEvent } from "../../../types/FormEvent";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/actionTypes";
import IClientBilling from "../../../interfaces/clients/IClientBilling";
import {
  createClientBilling,
  editClientBilling,
  fetchClientBillings,
} from "../../../store/clientReducer";
import { dateToInputDate } from "../../../functions/dateFunctions";

interface props {
  currentBilling?: IClientBilling;
  hide: () => void;
  action: boolean;
  updateBilling?: (billing: IClientBilling) => void;
}

export default function ClientBillingForm({
  currentBilling,
  hide,
  action,
  updateBilling,
}: props) {
  const { translate, uid } = UseAppContext();
  const [billing, setBilling] = useState(
    currentBilling || ({} as IClientBilling)
  );

  const editing = Boolean(currentBilling?.id);
  const dispatch: AppDispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action) {
      manageAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleClientChange = (e: FormEvent) => {
    const { name, value } = e.target;
    setBilling((prevBilling) => ({
      ...prevBilling,
      [name]: value,
    }));
  };

  function manageAction() {
    setLoading(true);
    if (editing) {
      return editBilling();
    }
    return createBilling();
  }

  async function editBilling() {
    try {
      const editBillingTask = (await dispatch(
        editClientBilling({ ...billing } as any)
      )) as any;

      if (editBillingTask.payload.id) {
        toast.success(translate("success"), {
          position: "top-center",
          autoClose: 2000,
        });

        dispatch(
          fetchClientBillings({ client: billing.client || "", professor: uid })
        );

        hide();
      } else {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
      }

      setLoading(false);
    } catch (e: any) {
      console.error(e);
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    }
  }

  async function createBilling() {
    try {
      const newBillingTask = (await dispatch(
        createClientBilling({ ...billing } as any)
      )) as any;

      if (newBillingTask.payload.id) {
        toast.success(translate("success"), {
          position: "top-center",
          autoClose: 2000,
        });

        dispatch(
          fetchClientBillings({ client: billing.client || "", professor: uid })
        );

        hide();
      } else {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
      }

      setLoading(false);
    } catch (e: any) {
      console.error(e);
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    }
  }

  function parseDate(date: any) {
    return dateToInputDate(date);
  }

  return (
    <div>
      <Form className="mb-4 mx-2 mt-2">
        <Form.Group controlId="value">
          <Form.Label>{translate("value")}</Form.Label>
          <Form.Control
            type="number"
            name="value"
            value={billing.value || ""}
            onChange={handleClientChange}
            required
            isInvalid={!Boolean(billing.value)}
            disabled={loading}
          />
        </Form.Group>

        <Form.Group controlId="dueDate">
          <Form.Label>{translate("dueDate")}</Form.Label>
          <Form.Control
            type="date"
            name="dueDate"
            value={parseDate(billing.dueDate)}
            onChange={handleClientChange}
            disabled={loading}
          />
        </Form.Group>

        <Form.Group controlId="info">
          <Form.Label>{translate("info")}</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            name="info"
            value={billing.info || ""}
            onChange={handleClientChange}
            disabled={loading}
          />
        </Form.Group>
      </Form>
    </div>
  );
}
