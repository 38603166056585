import { Col, Row } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";

export default function UserPlanCard() {
  const { user, translate, isMobile } = UseAppContext();

  return (
    <div className="border rounded p-2 bg-white">
      <h5>
        {translate("plan")}{" "}
        {translate("checkout." + user.planObject?.name || "")}
      </h5>
      <Row className={isMobile ? "px-2" : "px-4"}>
        <Col className="text-start" xs={5}>
          <p className="mb-1">
            <strong>{translate("function")}</strong>
          </p>
          <p className="mb-1">{translate("clients")}</p>
          <p className="mb-1">{translate("AiWorkouts")}</p>
        </Col>
        <Col className="text-start" xs={4}>
          <p className="mb-1">
            <strong>{translate("plan")} </strong>
          </p>
          <p className="mb-1">{user.planObject?.clients}</p>
          <p className="mb-1">{user.planObject?.workouts}</p>
        </Col>
        <Col className="text-start" xs={3}>
          <p className="mb-1">
            <strong>{translate("monthUsed")} </strong>
          </p>
          <p className="mb-1">{user.monthStats?.clients}</p>
          <p className="mb-1">{user.monthStats?.workouts}</p>
        </Col>
      </Row>
    </div>
  );
}
