import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IUser from "../interfaces/users/IUser";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebaseConfig";
import axios from "axios";
import IProfile from "../interfaces/users/IProfile";

type AppContextType = {
  translate: (key: string) => string;
  width: number;
  height: number;
  isMobile: boolean;
  isLogged: boolean;
  uid: string;
  user: IUser;
  setUser: (user: IUser) => void;
  loadingSession: boolean;
  logout: () => void;
  lang: string;
  setLang: (lang: string) => void;
};

export const AppContext = createContext<AppContextType>({
  translate: () => "",
  width: 700,
  height: 700,
  isMobile: true,
  isLogged: false,
  uid: "",
  user: {} as IUser,
  setUser: function (): void {},
  loadingSession: true,
  logout: function (): void {},
  lang: "pt",
  setLang: function (): void {},
});

export const UseAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

export const axiosContext = axios.create({
  baseURL: process.env.REACT_APP_BACK,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AppProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [logged, setLogged] = useState(false);
  const [uid, setUid] = useState("");
  const [user, setUser] = useState({} as IUser);
  const [loadingSession, setLoadingSession] = useState(true);
  const [lang, setLang] = useState("pt");

  useEffect(() => {
    try {
      const unsub = onAuthStateChanged(auth, async (user) => {
        setLoadingSession(true);
        if (user) {
          if (user.uid.length > 5) {
            const newUser = {} as IUser;
            newUser.id = user.uid;
            newUser.email = user.email || "";
            setUid(user.uid);
            setLogged(true);
            getUserData(newUser);
            setUser(newUser);
            await getIdToken(newUser);
            await getProfile();

            const reload = localStorage.getItem("reload");

            if (reload && reload === "true") {
              localStorage.setItem("reload", "");
              window.location.reload();
            }
          }
        } else {
          logout();
        }
      });
      return unsub;
    } catch (g) {
      console.error(g);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getProfile() {
    const response = await axiosContext.get("/app/user/fetchPersonalProfile");
    let newLang = "pt";
    if (response.data?.success) {
      const newProfile = response.data?.data as IProfile;
      newLang = newProfile.lang || "pt";
      setLang(newLang);
    }
    return newLang;
  }

  useEffect(() => {
    axiosContext.interceptors.request.use(
      async (config) => {
        if (user) {
          config.headers.lang = lang;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const getIdToken = async (user: IUser) => {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const newUser = { ...user };
      newUser.token = token;

      setUser(newUser);

      if (token) {
        axiosContext.interceptors.request.use(
          async (config) => {
            if (user) {
              config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      }
    } else {
      axiosContext.interceptors.request.use(
        async (config) => {
          config.headers.Authorization = ``;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
    return null;
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function getUserData(user: IUser) {
    try {
      setLoadingSession(false);
    } catch (error: any) {
      setLoadingSession(false);
      console.error(
        "Error get user:",
        error.response?.data || error.message || error
      );
    }
  }

  async function logout() {
    await auth.signOut();
    setUid("");
    setLogged(false);
    setLoadingSession(false);
    setUser({});
    getIdToken({});
    localStorage.setItem("reload", "true");
  }

  return (
    <AppContext.Provider
      value={{
        translate: t,
        width,
        height,
        isMobile: width < 767,
        isLogged: logged,
        uid,
        user,
        setUser,
        loadingSession,
        logout,
        lang,
        setLang,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
