import { Button, Card, Modal, Spinner } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { useEffect, useState } from "react";
import IClientBilling from "../../../interfaces/clients/IClientBilling";
import ClientBillingForm from "./ClientBillingForm";

interface props {
  show: boolean;
  hide: () => void;
  currentBilling?: IClientBilling;
  updateBilling?: (billing: IClientBilling) => void;
}

export default function ClientBillingModal({
  show,
  hide,
  currentBilling,
  updateBilling,
}: props) {
  const { translate } = UseAppContext();
  const [action, setAction] = useState(false);
  const editing = Boolean(currentBilling?.id);

  useEffect(() => {
    if (action) {
      const timer = setTimeout(() => {
        setAction(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [action]);

  useEffect(() => {
    setAction(false);
  }, [currentBilling]);

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header className="py-2 text-center">
        <h4 className="text-center my-0 mx-auto">
          {translate(editing ? "editBilling" : "createBilling")}
        </h4>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Card
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(85vh - 80px)",
            border: "none",
          }}
          className="px-2"
        >
          <ClientBillingForm
            currentBilling={currentBilling}
            hide={hide}
            action={action}
            updateBilling={(val: IClientBilling) => {
              if (updateBilling) {
                updateBilling(val);
              }
            }}
          />
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          disabled={action}
          onClick={() => {
            setAction(true);
          }}
        >
          {action && <Spinner size="sm" className="me-2" />}
          {translate(editing ? "editBilling" : "createBilling")}
        </Button>
        <Button onClick={hide} variant="secondary" disabled={action}>
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
