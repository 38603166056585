import { UseAppContext } from "../../../context/appContext";
import { Accordion, Button, Spinner } from "react-bootstrap";
import WorkoutExcerciceIntervals from "./WorkoutExcerciceIntervals";
import { FaCheckSquare, FaInfo, FaRegSquare } from "react-icons/fa";
import IWeek from "../../../interfaces/workouts/IWeek";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditExerciseButton from "../exercises/EditExerciseButton";
import IExercise from "../../../interfaces/workouts/IExercice";
import { MdDelete } from "react-icons/md";
import { useEffect, useState } from "react";
import ExerciseInfoModal from "../exercises/exerciseInfo/ExerciseInfoModal";
import UseImage from "../../../helpers/UseImage";

interface props {
  exercise: IExercise;
  day: number;
  week: number;
  weeks: Array<IWeek>;
  exercisePosition: number;
  dayDone: boolean;
  updateExercise: (
    exercisePosition: number,
    newExercise: IExercise,
    switchDone: boolean
  ) => void;
  removeExercise: () => void;
}

export default function WorkoutExercise({
  exercise,
  updateExercise,
  exercisePosition,
  dayDone,
  removeExercise,
}: props) {
  const { translate, isMobile, user } = UseAppContext();
  const loadingWorkouts = useSelector(
    (state: any) => state.workout.loading
  ) as Boolean;
  const [confirm, setConfirm] = useState(false);
  const isProfessor = user.type === "professor";
  const [showInfo, setShowInfo] = useState(false);
  const [payloadExeInfo, setPayloadExeInfo] = useState({
    exercise: "",
    name: "",
  });

  useEffect(() => {
    if (confirm) {
      const timer = setTimeout(() => {
        console.log("Consulta ejecutada después de 5 segundos");
        setConfirm(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [confirm]);

  function seriesTitle() {
    if (Number(exercise?.series) > 0) {
      if (Number(exercise?.repetitions) > 0) {
        return `- ${exercise.series} x ${exercise.repetitions}`;
      }
      return `- ${exercise.series}`;
    }

    return "";
  }

  function actionUpdate() {
    if (!dayDone) {
      return updateExercise(exercisePosition, exercise, true);
    }

    toast.warning(translate("dayEndedInfo"), {
      position: "top-center",
      autoClose: 3000,
    });
  }

  function actionUpdateExercise(newExercice: IExercise) {
    if (!dayDone) {
      return updateExercise(exercisePosition, newExercice, false);
    }

    toast.warning(translate("dayEndedInfo"), {
      position: "top-center",
      autoClose: 3000,
    });
  }

  async function getExerciceInfo(exercise: string, name: string) {
    const newPayload = { exercise, name };

    setPayloadExeInfo(newPayload);
    setShowInfo(true);
  }

  const [iconImage, setIconImage] = useState("");

  const { image } = UseImage(`muscularGroups/${exercise.muscularGroup}.png`);

  useEffect(() => {
    if (image) {
      setIconImage(image);
    }
  }, [image]);

  return (
    <Accordion.Item
      eventKey={exercisePosition?.toString() || ""}
      className={exercise.done ? "border-success bg-successl" : ""}
    >
      <Accordion.Header className="d-flex">
        <EditExerciseButton
          className={isMobile ? "me-1" : "me-2"}
          exercise={exercise}
          updateExercice={actionUpdateExercise}
        />
        <div className="d-flex " style={{ width: "100%" }}>
          <p className="text-12 my-auto">
            {exercise.name} {seriesTitle()}{" "}
          </p>
          <div className="me-0 ms-auto pe-2" style={{ minHeight: "21px" }}>
            {loadingWorkouts ? (
              <Spinner size="sm" />
            ) : exercise.done ? (
              <FaCheckSquare
                className="pointer"
                size="20"
                onClick={(e) => {
                  e.stopPropagation();
                  actionUpdate();
                }}
              />
            ) : (
              <FaRegSquare
                className="pointer"
                size="20"
                onClick={(e) => {
                  e.stopPropagation();
                  actionUpdate();
                }}
              />
            )}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body className="py-2">
        <div className="d-flex">
          <div className="w-100">
            {iconImage && (
              <img
                alt={"exercice"}
                width="40"
                src={iconImage}
                className="mb-1"
              />
            )}
            {exercise.series && (
              <p className="mb-0">
                {translate("series")} {exercise.series}
              </p>
            )}

            {exercise.repetitions && (
              <p className="mb-0">
                {translate("repetitions")} {exercise.repetitions}
              </p>
            )}

            {exercise.duration && (
              <p className="mb-0">
                {translate("duration")} {exercise.duration}
              </p>
            )}

            {exercise.distance && (
              <p className="mb-0">
                {translate("distance")} {exercise.distance}
              </p>
            )}

            {exercise.intensity && (
              <p className="mb-0">
                {translate("intensity")} {exercise.intensity}
              </p>
            )}

            {exercise.weight && (
              <p className="mb-0">
                {translate("weight")} {exercise.weight}
              </p>
            )}

            {exercise.rest && (
              <p className="mb-0">
                {translate("rest")} {exercise.rest}
              </p>
            )}

            {exercise.pace && (
              <p className="mb-0">
                {translate("pace")}: {exercise.pace}
              </p>
            )}

            {exercise.time && (
              <p className="mb-0">
                {translate("time")} {exercise.time}
              </p>
            )}
          </div>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                getExerciceInfo(exercise.exercise || "", exercise.name || "");
              }}
            >
              <FaInfo size={12} />
            </Button>
          </div>
        </div>

        {exercise.info && <small>{exercise.info}</small>}

        <WorkoutExcerciceIntervals intervals={exercise.intervals} />

        {isProfessor && !exercise.done && (
          <div className="d-flex mt-2">
            <div className="mx-auto d-flex">
              <Button
                size="sm"
                className=""
                variant={confirm ? "danger" : "primary"}
                onClick={() => {
                  if (!confirm) {
                    setConfirm(true);
                    return;
                  }
                  removeExercise();
                }}
              >
                {confirm ? translate("confirmDelete") : <MdDelete />}
              </Button>

              <EditExerciseButton
                className={"ms-2"}
                buttonVariant="primary"
                buttonSize="32px"
                exercise={exercise}
                updateExercice={actionUpdateExercise}
              />
            </div>
          </div>
        )}
        <ExerciseInfoModal
          show={showInfo}
          hide={() => {
            setShowInfo(false);
          }}
          exercise={payloadExeInfo.exercise}
          name={payloadExeInfo.name}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
