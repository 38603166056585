import IDay from "../interfaces/workouts/IDay";
import IWeek from "../interfaces/workouts/IWeek";
import IWorkoutResponse from "../interfaces/workouts/IWorkoutResponse";

export function variant(done?: boolean) {
  return done ? "success" : "primary";
}

export function workoutDone(workout: IWorkoutResponse) {
  if (!workout) return false;

  return !workout?.weeks?.some((week) => weekDone(week) === false);
}

export function weekDone(week: IWeek) {
  if (!week) return false;

  return !week?.days?.some((day) => dayDone(day) === false);
}

export function dayDone(day: IDay) {
  if (!day) return false;

  return !day?.exercises?.some((excercise) => excercise.done === false);
}
