import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./actionTypes";
import IUser from "../interfaces/users/IUser";
import { axiosContext } from "../context/appContext";
import IProfile from "../interfaces/users/IProfile";
import ISubscription from "../interfaces/subscriptions/ISubscription";
import INotification from "../interfaces/notifications/INotification";
import { firestoreDateToDate } from "../functions/dateFunctions";

const initialState: UserState = {
  loadedProfile: false,
  user: {} as IUser,
  personalProfile: {} as IProfile,
  professionalProfile: {} as IProfile,
  subcriptions: [] as ISubscription[],
  loadingSubcriptions: false,
  notifications: [] as INotification[],
  loadingNotifications: false,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  try {
    const response = await axiosContext.get("/app/auth/verifyUser");
    if (response.data?.success) {
      const newProfile = response.data?.data as IUser;
      return newProfile;
    }
    return {} as IUser;
  } catch (error) {
    console.error("Error fetching data:", error);
    return {} as IUser;
  }
});

export const fetchPayments = createAsyncThunk(
  "user/fetchPayments",
  async () => {
    try {
      const response = await axiosContext.get("/app/auth/verifyPayments");
      if (response.data?.success) {
        const newProfile = response.data?.data as IUser;
        return newProfile;
      }
      return {} as IUser;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IUser;
    }
  }
);

export const fetchPersonalProfile = createAsyncThunk(
  "user/fetchPersonalProfile",
  async () => {
    try {
      const response = await axiosContext.get("/app/user/fetchPersonalProfile");
      if (response.data?.success) {
        const newProfile = response.data?.data as IProfile;
        return newProfile;
      }
      return {} as IProfile;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IProfile;
    }
  }
);

export const fetchProfessionalProfile = createAsyncThunk(
  "user/fetchProfessionalProfile",
  async () => {
    try {
      const response = await axiosContext.get(
        "/app/user/fetchProfessionalProfile"
      );
      if (response.data?.success) {
        const newProfile = response.data?.data as IProfile;
        return newProfile;
      }
      return {} as IProfile;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IProfile;
    }
  }
);

export const fetchSubscriptions = createAsyncThunk(
  "user/fetchSubscriptions",
  async () => {
    try {
      const response = await axiosContext.get(
        "/app/subcription/getSubscriptions"
      );
      if (response.data?.success) {
        const subscriptions = response.data?.data as Array<ISubscription>;
        return subscriptions;
      }
      return [] as Array<ISubscription>;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [] as Array<ISubscription>;
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  "user/fetchNotifications",
  async () => {
    try {
      const response = await axiosContext.get("/app/user/getUserNotifications");
      if (response.data?.success) {
        const notifications = response.data?.data as Array<INotification>;
        return notifications.map((notification) => {
          const parsedNotification = { ...notification };

          parsedNotification.date = parsedNotification.date
            ? firestoreDateToDate(parsedNotification.date)
            : undefined;
          parsedNotification.readDate = parsedNotification.readDate
            ? firestoreDateToDate(parsedNotification.date)
            : undefined;
          return parsedNotification;
        });
      }
      return [] as Array<INotification>;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [] as Array<INotification>;
    }
  }
);

export const updateNotification = createAsyncThunk(
  "user/updateNotification",
  async (notification: INotification) => {
    try {
      const response = await axiosContext.post(
        "/app/user/updateUserNotification",
        notification
      );
      if (response.data?.success) {
        const notification = response.data?.data as string;
        return Boolean(notification);
      }
      return false;
    } catch (error) {
      console.error("Error fetching data:", error);
      return false;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStoreUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setPersonalProfile: (state, action: PayloadAction<IProfile>) => {
      state.personalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    },
    setProfessionalProfile: (state, action: PayloadAction<IProfile>) => {
      state.professionalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptions.pending, (state) => {
      state.loadingSubcriptions = true;
    });
    builder.addCase(fetchSubscriptions.fulfilled, (state, action) => {
      state.subcriptions = action.payload;
      state.loadingSubcriptions = false;
    });
    builder.addCase(fetchSubscriptions.rejected, (state) => {
      state.loadingSubcriptions = false;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchPayments.fulfilled, () => {});
    builder.addCase(fetchPersonalProfile.fulfilled, (state, action) => {
      state.personalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    });
    builder.addCase(fetchProfessionalProfile.fulfilled, (state, action) => {
      state.professionalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    });

    builder.addCase(fetchNotifications.pending, (state) => {
      state.loadingNotifications = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loadingNotifications = false;
    });
    builder.addCase(fetchNotifications.rejected, (state) => {
      state.loadingNotifications = false;
    });

    builder.addCase(updateNotification.pending, (state) => {
      state.loadingNotifications = true;
    });
    builder.addCase(updateNotification.fulfilled, (state, action) => {
      state.loadingNotifications = false;
    });
    builder.addCase(updateNotification.rejected, (state) => {
      state.loadingNotifications = false;
    });
  },
});

export const { setStoreUser, setPersonalProfile, setProfessionalProfile } =
  userSlice.actions;
export default userSlice.reducer;
