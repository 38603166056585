import { FaCircle, FaSpinner } from "react-icons/fa";
import { UseAppContext } from "../../../context/appContext";
import INotification from "../../../interfaces/notifications/INotification";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  updateNotification,
} from "../../../store/userReducer";
import { AppDispatch } from "../../../store/actionTypes";
import IClient from "../../../interfaces/IClient";

interface Props {
  notification?: INotification;
}

export default function NotificationItem({ notification }: Props) {
  const { isMobile, translate } = UseAppContext();
  const [loading, setLoading] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(notification);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [notificationText, setNotificationText] = useState(
    currentNotification?.info || ""
  );

  const clients = useSelector(
    (state: any) => state.client.clients
  ) as Array<IClient>;

  const loadingClients = useSelector(
    (state: any) => state.client.loading
  ) as Boolean;

  useEffect(() => {
    if (!loadingClients) {
      const currentClient = clients.find(
        (client) => client.id === currentNotification?.client
      );

      if (currentClient) {
        const newText = currentNotification?.info?.replace(
          "$client",
          '"' + currentClient.name + '"'
        );
        setNotificationText(newText || "");
      }
    }
  }, [clients, loadingClients, currentNotification]);

  useEffect(() => {
    setCurrentNotification(notification);
  }, [notification]);

  async function editNotification() {
    const newNotification = {
      id: currentNotification?.id,
      read: Boolean(!currentNotification?.read),
    } as INotification;

    setLoading(true);
    await dispatch(updateNotification(newNotification) as any);
    dispatch(fetchNotifications() as any);
    setLoading(false);
  }

  function notificationClick() {
    if (loading) {
      return;
    }
    if (currentNotification?.type === "subscription") {
      console.error(currentNotification);
      navigate(`/client/${currentNotification.client}`);
      if (!currentNotification.read) {
        editNotification();
      }
    }

    if (currentNotification?.type === "workoutEnd") {
      console.error(currentNotification);
      navigate(
        `/client/${currentNotification.client}/${currentNotification.workout}`
      );
      if (!currentNotification.read) {
        editNotification();
      }
    }
  }

  return (
    <div
      className="d-flex p-2 mx-2 my-1 hover-notification rounded"
      style={{ minWidth: isMobile ? "90vw" : "280px" }}
    >
      {currentNotification && (
        <div
          className="d-flex"
          onClick={() => {
            editNotification();
          }}
        >
          {loading ? (
            <FaSpinner size={8} className="me-2 my-auto spin" />
          ) : (
            <FaCircle
              className="me-2 my-auto"
              size={8}
              color={
                currentNotification.read
                  ? "var(--secondary)"
                  : "var(--notification)"
              }
            />
          )}
        </div>
      )}
      <div>
        <p className="mb-0 text-10">
          {currentNotification?.date?.toLocaleDateString()}
        </p>
        <p
          onClick={() => {
            notificationClick();
          }}
          className="text-12 mb-0"
          style={{ lineHeight: 1 }}
        >
          {notificationText || translate("noNotifications")}
        </p>
      </div>
    </div>
  );
}
