import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/actionTypes";
import { useEffect, useState } from "react";
import IWorkoutResponse from "../../../interfaces/workouts/IWorkoutResponse";
import { Button } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { fetchClientWorkouts } from "../../../store/workoutReducer";
import ClientWorkoutModal from "./ClientWorkoutModal";
import IWorkoutRequest from "../../../interfaces/workouts/IWorkoutRequest";
import CreatingWorkoutModal from "../modals/CreatingWorkoutModal";
import { useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import WorkoutSimpleCard from "../card/WorkoutSimpleCard";
import LoadingDiv from "../../loading/LoadingDiv";

export default function ClientWorkouts() {
  const dispatch: AppDispatch = useDispatch();
  const [currentWorkout, setCurrentWorkout] = useState({} as IWorkoutResponse);
  const workouts = useSelector(
    (state: any) => state.workout.clientWorkouts
  ) as Array<IWorkoutResponse>;
  const [haveWorkouts, setHaveWorkouts] = useState(Boolean(workouts.length));
  const { translate, isMobile } = UseAppContext();
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [workoutToSend, setWoroutToSend] = useState({} as IWorkoutRequest);

  const loadingWorkouts = useSelector(
    (state: any) => state.workout.loading
  ) as Boolean;

  const { client } = useParams();

  useEffect(() => {
    if (client) {
      dispatch(fetchClientWorkouts(client));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    setHaveWorkouts(Boolean(workouts.length));
  }, [workouts]);

  function createWorkoutAction() {
    setCurrentWorkout({} as IWorkoutResponse);
    setShow(true);
  }

  return (
    <div className={" " + (isMobile ? "mt-3" : "")}>
      {client ? (
        <div>
          {!haveWorkouts && <p>{translate("createFirstWorkoutInfo")}</p>}

          {loadingWorkouts && <LoadingDiv />}

          {haveWorkouts && (
            <div className="d-flex flex-row mb-1">
              <h6 className="my-auto ms-2">{translate("workoutsList")}</h6>
              <Button
                className="mb-0 ms-auto me-0"
                size="sm"
                variant="primary"
                onClick={() => {
                  createWorkoutAction();
                }}
              >
                <FaPlus className="me-2" />
                {translate("createWorkout")}
              </Button>
            </div>
          )}

          {workouts?.map((workout, index) => {
            return (
              <WorkoutSimpleCard
                key={`${workout.id}-${index}`}
                currentWorkout={workout}
              />
            );
          })}
        </div>
      ) : (
        <div>{translate("noClientSelectedYet")}</div>
      )}

      <ClientWorkoutModal
        currentWorkout={currentWorkout}
        show={show}
        hide={() => {
          setShow(false);
        }}
        showCreating={(workoutToSend: IWorkoutRequest) => {
          if (workoutToSend && workoutToSend.client) {
            setWoroutToSend(workoutToSend);
            setShowCreate(true);
            setShow(false);
          }
        }}
      />

      <CreatingWorkoutModal
        show={showCreate && Boolean(workoutToSend.client)}
        hide={() => {
          setShowCreate(false);
        }}
        workoutToSend={workoutToSend}
      />
    </div>
  );
}
