import ClientContainer from "../components/clients/container/ClientContainer";
import AppContainer from "../containers/AppContainer";

export default function Client() {
  return (
    <AppContainer>
      <div className="p-2">
        <ClientContainer />
      </div>
    </AppContainer>
  );
}
