import { Button, Modal } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import SubcribeForm from "./SubscribeForm";

interface props {
  show: boolean;
  hide: () => void;
}

export default function SubcribeModal({ show, hide }: props) {
  const { translate } = UseAppContext();

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Body>
        <SubcribeForm hide={hide} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide} variant="secondary">
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
