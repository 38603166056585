import { useParams } from "react-router-dom";
import { UseAppContext } from "../../../context/appContext";
import { useSelector } from "react-redux";
import IClient from "../../../interfaces/IClient";
import ClientCard from "../ClientCard";
import { useEffect, useState } from "react";
import ClientWorkouts from "../../workouts/client/ClientWorkouts";
import ClientBillingList from "../billing/ClientBillingList";

export default function ClientContainer() {
  const { isMobile, translate } = UseAppContext();
  const { client } = useParams();
  const [item, setItem] = useState("workouts");
  const clients = useSelector(
    (state: any) => state.client.clients
  ) as Array<IClient>;

  const currentClientFind = clients?.find(
    (clientObject) => clientObject.id === client
  );

  const [currentClient, setCurrentClient] = useState(currentClientFind);

  useEffect(() => {
    const newClient = clients?.find(
      (clientObject) => clientObject.id === client
    );

    setCurrentClient(newClient);
  }, [client, clients]);

  return (
    <div className={"" + (isMobile ? "mt-3" : "")}>
      {client ? (
        <div>
          {currentClient && (
            <div className="mb-3">
              <ClientCard
                client={currentClient}
                forceImage
                setItem={(item) => {
                  setItem(item);
                }}
              />
            </div>
          )}
          {item === "workouts" && <ClientWorkouts />}
          {item === "billing" && <ClientBillingList client={client} />}
        </div>
      ) : (
        <div>{translate("noClientSelectedYet")}</div>
      )}
    </div>
  );
}
