import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientState } from "./actionTypes";
import IClient from "../interfaces/IClient";
import { axiosContext } from "../context/appContext";
import IClientBilling from "../interfaces/clients/IClientBilling";
import { firestoreDateToDate } from "../functions/dateFunctions";

const initialState: ClientState = {
  client: {} as IClient,
  clients: [] as Array<IClient>,
  loadingBillig: false,
  clientBillings: [] as Array<IClientBilling>,
  loading: false,
};

export const fetchClients = createAsyncThunk(
  "client/fetchClients",
  async () => {
    try {
      const response = await axiosContext.get("/app/clients/getClients");
      if (response.data?.success) {
        const newClients = response.data?.data || ([] as Array<IClient>);

        newClients.sort((a: IClient, b: IClient) => {
          if (a.active === b.active) {
            return a.name.localeCompare(b.name);
          }
          return a.active ? -1 : 1;
        });
        return newClients;
      }
      return [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
);

export const fetchClientBillings = createAsyncThunk(
  "client/fetchClientBillings",
  async (payload: { client: string; professor: string }) => {
    try {
      const response = await axiosContext.get(
        `/app/subcription/getSubscriptionBillings/?client=${payload.client}&professor=${payload.professor}`
      );
      if (response.data?.success) {
        const billings = response.data?.data || ([] as Array<IClientBilling>);

        const newBillings = billings.map((billing: IClientBilling) => {
          const newBilling = { ...billing };
          newBilling.date = firestoreDateToDate(newBilling.date);
          newBilling.dueDate = firestoreDateToDate(newBilling.dueDate);
          return newBilling;
        });

        return newBillings;
      }
      return [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
);

export const createClientBilling = createAsyncThunk(
  "client/createClientBilling",
  async (payload: {
    client: string;
    duaDate?: Date;
    value: number;
    invoice?: string;
  }) => {
    payload.value = parseFloat(payload.value?.toString());

    try {
      const response = await axiosContext.post(
        `/app/subcription/createSubcriptionBilling`,
        payload
      );
      if (response.data?.success) {
        const billing =
          (response.data?.data as IClientBilling) || ({} as IClientBilling);

        billing.date = firestoreDateToDate(billing.date);
        billing.dueDate = firestoreDateToDate(billing.date);

        return billing;
      }
      return {} as IClientBilling;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IClientBilling;
    }
  }
);

export const editClientBilling = createAsyncThunk(
  "client/updateSubcriptionBilling",
  async (payload: IClientBilling) => {
    try {
      const response = await axiosContext.post(
        `/app/subcription/updateSubcriptionBilling`,
        payload
      );
      if (response.data?.success) {
        const billing =
          (response.data?.data as IClientBilling) || ({} as IClientBilling);

        billing.date = firestoreDateToDate(billing.date);
        billing.dueDate = firestoreDateToDate(billing.date);

        return billing;
      }
      return {} as IClientBilling;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IClientBilling;
    }
  }
);

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setStoreClient: (state, action: PayloadAction<IClient>) => {
      state.client = action.payload;
    },
    setStoreClients: (state, action: PayloadAction<Array<IClient>>) => {
      state.clients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => {
      state.clients = [];
      state.loading = true;
    });
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchClients.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchClientBillings.pending, (state) => {
      state.clientBillings = [];
      state.loadingBillig = true;
    });
    builder.addCase(fetchClientBillings.fulfilled, (state, action) => {
      state.clientBillings = action.payload;
      state.loadingBillig = false;
    });
    builder.addCase(fetchClientBillings.rejected, (state) => {
      state.loadingBillig = false;
    });

    builder.addCase(createClientBilling.pending, (state) => {
      state.loadingBillig = true;
    });
    builder.addCase(createClientBilling.fulfilled, (state, action) => {
      state.loadingBillig = false;
    });
    builder.addCase(createClientBilling.rejected, (state) => {
      state.loadingBillig = false;
    });

    builder.addCase(editClientBilling.pending, (state) => {
      state.loadingBillig = true;
    });
    builder.addCase(editClientBilling.fulfilled, (state, action) => {
      state.loadingBillig = false;
    });
    builder.addCase(editClientBilling.rejected, (state) => {
      state.loadingBillig = false;
    });
  },
});

export const { setStoreClient, setStoreClients } = clientSlice.actions;
export default clientSlice.reducer;
