import { useLocation, useNavigate } from "react-router-dom";
import { UseAppContext } from "../../context/appContext";
import { Button } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import NotificationsDropDown from "./notifications/NotificationsDropdown";

export default function WebAppNavbar() {
  const { translate, isLogged, logout, isMobile } = UseAppContext();
  const location = useLocation();
  const canBack = location.pathname !== "/";
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  function goHome() {
    navigate("/");
  }

  return (
    <div
      className="text-center bg-primary text-white d-flex"
      style={{ minHeight: "40px" }}
    >
      <div
        className={"d-flex mx-auto w-100"}
        style={{ maxWidth: isMobile ? undefined : "700px" }}
      >
        {canBack ? (
          <Button
            variant="primary"
            onClick={() => {
              goBack();
            }}
            size="sm"
            className="d-flex mt-2"
            style={{ maxHeight: "28px" }}
          >
            <FaChevronLeft color="var(--text)" className="m-auto" />
          </Button>
        ) : (
          isLogged && <div style={{ width: "32px" }} />
        )}

        <p
          className={"ms-2 my-auto " + (canBack ? "pointer" : "")}
          onClick={() => {
            goHome();
          }}
        >
          {translate("appName")}
        </p>

        {isLogged && (
          <div className="me-0 ms-auto d-flex">
            <NotificationsDropDown />
            <Button
              variant="primary"
              onClick={() => {
                logout();
              }}
              size="sm"
              className="d-flex my-auto"
              style={{ maxHeight: "28px" }}
            >
              <MdLogout color="var(--text)" className="m-auto" size={20} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
