import { useLocation, useNavigate } from "react-router-dom";
import { UseAppContext } from "../../context/appContext";
import { Button } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import NotificationsDropDown from "./notifications/NotificationsDropdown";

export default function MobileAppNavbar() {
  const { translate, isLogged, logout } = UseAppContext();
  const location = useLocation();
  const canBack = location.pathname !== "/";
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  function goHome() {
    navigate("/");
  }

  function titleClass() {
    if (!isLogged) {
      return "m-auto";
    }

    return `me-auto my-auto ${canBack ? "ms-1 pointer" : "ms-3"}`;
  }

  return (
    <div
      className="text-center bg-primary text-white d-flex"
      style={{ minHeight: "40px" }}
    >
      {canBack && (
        <Button
          variant="primary"
          onClick={() => {
            goBack();
          }}
          size="sm"
          className="d-flex mt-2"
          style={{ maxHeight: "28px" }}
        >
          <FaChevronLeft color="var(--text)" className="m-auto" />
        </Button>
      )}

      <p
        onClick={() => {
          goHome();
        }}
        className={titleClass()}
      >
        {translate("appName")}
      </p>

      {isLogged && (
        <>
          <NotificationsDropDown />
          <Button
            variant="primary"
            onClick={() => {
              logout();
            }}
            size="sm"
            className="d-flex my-auto"
            style={{ maxHeight: "28px" }}
          >
            <MdLogout color="var(--text)" className="m-auto" size={20} />
          </Button>
        </>
      )}
    </div>
  );
}
